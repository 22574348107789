import React, {useState, useEffect, useMemo, useRef} from 'react';
import RenderRootComponent from 'components/render-utils/render-root-component';
import {hot} from 'react-hot-loader';
import Modal from 'components/modal';
import {store} from "app/redux/store";
import {postAddressValidation, modifyCustomerPCode, verifyCustomerAddress} from 'app/api/address-validation';
import {exchange_credentials as exchangeCredentials} from "app/django-adapter";
import LoadingState from "components/loading-state";
import countries from 'app/api/countries';

import './style.scss';

let validationState = 'NOT_STARTED';

export const AddressModal = ({addressData}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [modalType, setModalType] = useState('INVALID');
  const [suggestionAddress, setSuggestionAddress] = useState({});
  const [pCode, setPCode] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isWarningModalVisible, setIsWarningModalVisible] = useState(false);
  const [isEditAddress, setIsEditAddress] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [addressInputs, setAddressInputs] = useState({iso_country: 'US'});
  const [addressValidation, setAddressValidation] = useState({
    street: true, street2: true, city: true, state: true, postal_code: true, iso_country: true,
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  let currentAddressElement;

  const {
    addressModalFirstName,
    addressModalLastName,
    addressModalLine1,
    addressModalLine2,
    addressModalCity,
    addressModalState,
    addressModalCountry,
    addressModalZip
  } = addressData;

  const preferencesLinkRef = useRef(null);
  const useSuggestedAddressRef = useRef(null);

  if (validationState === 'STARTED') {
    const {street, street2, city, state, postal_code, iso_country} = addressInputs;
    currentAddressElement = useMemo(() => <div className="square-bordered-info">
      {street ? <> {street} <br/> </> : null}
      {street2 ? <> {street2} <br/> </> : null}
      {city}, {state} <br/>
      {postal_code}
    </div>, [addressInputs])
  } else {
    currentAddressElement = useMemo(() => <div className="square-bordered-info">
      {addressModalLine1 ? <> {addressModalLine1} <br/> </> : null}
      {addressModalLine2 ? <> {addressModalLine2} <br/> </> : null}
      {addressModalCity}, {addressModalState} <br/>
        {addressModalZip}
    </div>, [addressData])
  }

  if (countryList.length === 0) {
    countries.get().then((res) => {
      setCountryList(res.data)
    }).catch((error) => console.log("error = ", error))
  }

  const getModalType = (res) => {
    console.log('called getModalType');
    const isAttributesPresent = res && res.data && res.data.attributes;
    const isSuggestion = isAttributesPresent && res.data.attributes.validatedAddress;
    const isInvalid = isAttributesPresent && !res.data.attributes.success;
    const isPerfect = (isAttributesPresent && res.data.attributes.success
      && !res.data.attributes.validated_address);

    console.log(isAttributesPresent, isSuggestion, isInvalid, isPerfect);

    if (isSuggestion) {
      return 'SUGGESTION';
    } else if (isInvalid) {
      return 'INVALID';
    } else if (isPerfect) {
      return 'PERFECT';
    } else {
      return 'INVALID'
    }
  }

  const setVerify = () => verifyCustomerAddress().then(() => {
  }).catch((error) => console.log("verify customer address error = ", error))

  const setVerifyAndGoToPreferences = async () => {
    await setVerify();

    if (modalType === 'PERFECT') {
      const data = {
        address_pcode: pCode,
        ...(addressModalCity && {city: addressModalCity}),
        ...(addressModalState && {state: addressModalState}),
        ...(addressModalZip && {postal_code: addressModalZip}),
        ...(addressModalCountry && {iso_country: addressModalCountry}),
        ...(addressModalLine1 && {street: addressModalLine1}),
        ...(addressModalLine2 && {street2: addressModalLine2}),
      };

      modifyCustomerPCode(data).then(() => {
      }).catch((error) => console.log("error = ", error))
    }

    setIsModalVisible(false);
    preferencesLinkRef.current.click()
  }

  const setModifyCustomerPCode = async () => {
    console.log('called setModifyCustomerPCode')
    const {
      addressModalLine1,
      addressModalLine2,
      addressModalCity,
      addressModalState,
      addressModalCountry,
      addressModalZip
    } = suggestionAddress;

    const data = {
      address_pcode: pCode,
      ...(addressModalCity && {city: addressModalCity}),
      ...(addressModalState && {state: addressModalState}),
      ...(addressModalZip && {postal_code: addressModalZip}),
      ...(addressModalCountry && {iso_country: addressModalCountry}),
      ...(addressModalLine1 && {street: addressModalLine1}),
      ...(addressModalLine2 ? {street2: addressModalLine2} : {street2: ''}),
    };

    await setVerify();
    await modifyCustomerPCode(data);
    setIsModalVisible(false);
    modalType === 'SUGGESTION' && useSuggestedAddressRef.current.click();
  }

  const useCurrentAddress = async () => {
    await setVerify();
    setIsModalVisible(false);
  }

  const validateAddress = (isEdit) => {
    const addressData = {
      ...(addressModalCity && {city: addressModalCity}),
      ...(addressModalState && {state: addressModalState}),
      ...(addressModalZip && {postal_code: addressModalZip}),
      ...(addressModalCountry && {iso_country: addressModalCountry}),
      ...(addressModalLine1 && {street: addressModalLine1}),
      ...(addressModalLine2 && {street2: addressModalLine2}),
    };
    const data = {
      data: {
        type: "address",
        attributes: validationState === 'STARTED' ? addressInputs : addressData
      }
    }
    postAddressValidation(data).then(async (res) => {
      const modalType = getModalType(res);
      const pCode = res && res.data && res.data.attributes && res.data.attributes.pcode;
      if (modalType === 'PERFECT') {
        const addressValue = validationState === 'STARTED' ? {...{address_pcode: pCode}, ...addressInputs} :
          {...{address_pcode: pCode}, ...addressData};
        await setVerify();
        modifyCustomerPCode(addressValue).then(() => {
          validationState = 'DONE'
          setIsSubmitted(false)
        }).catch((error) => console.log("error = ", error))
        setIsModalVisible(false);
        if(isEdit){
          useSuggestedAddressRef.current.click()
        }
        return
      }
      setIsModalVisible(true);
      if (modalType === 'SUGGESTION') {
        const {city, state, postalCode, isoCountry, street, street2} = res.data.attributes.validatedAddress;
        setSuggestionAddress({
          addressModalLine1: street, addressModalLine2: street2,
          addressModalCity: city, addressModalState: state,
          addressModalCountry: isoCountry, addressModalZip: postalCode
        })
      }
      setPCode(pCode);
      setModalType(modalType);
      setIsLoading(false);
      // on api error, assume address is invalid and proceed
    }).catch((error) => {
      setIsModalVisible(true);
      setModalType('INVALID');
      setIsLoading(false)
    })
  }

  const init = () => {
    useEffect(() => {
      validateAddress()
    }, []);
  }
  init()

  const handlingWarningModal = (flag) => {
    setIsWarningModalVisible(flag)
  }

  const handleAddressChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setAddressValidation(values => ({...values, [name]: value ? false : true}))
    setAddressInputs(values => ({...values, [name]: value}))
  }

  const handleAddressUpdate = (event) => {
    event.preventDefault();
    setIsSubmitted(true)
    if (!addressInputs.street || !addressInputs.city ||
      !addressInputs.state || !addressInputs.postal_code || !addressInputs.iso_country) {
      return
    }
    validationState = 'STARTED'
    setIsLoading(true);
    validateAddress(true)
    setIsEditAddress(false)
  }

  const openEditMyAddress = () => {
    setIsEditAddress(true)
    setIsSubmitted(false)
    if (validationState !== 'STARTED') {
      setAddressInputs({
        street: addressModalLine1,
        street2: addressModalLine2, city: addressModalCity, state: addressModalState, postal_code: addressModalZip,
        iso_country: addressModalCountry
      })
      setAddressValidation({
        street: !addressModalLine1,
        street2: !addressModalLine2, city: !addressModalCity, state: !addressModalState, postal_code: !addressModalZip,
        iso_country: !addressModalCountry
      })
    }
  }

  return (
    <div className="address-modal-app">
      <a className="d-none"
         href="/accounts/preferences/" ref={preferencesLinkRef}/>
      <a className="d-none"
         href="/accounts/preferences?modal_use_suggested_address=1"
         ref={useSuggestedAddressRef}/>
      <Modal
        className="address-modal"
        title={isEditAddress ? "Edit Address" : "Help Us Keep Your Records Accurate"}
        titleClassName="content-title"
        isShow={isModalVisible}
        showCloseButton={true}
        onClose={() => {
          if(!isEditAddress){
            handlingWarningModal(true)
          }else {
            setIsEditAddress(false);
            setIsSubmitted(false)
          }
        }}
      >
        {isLoading ? <div className="address-modal__loader text-center">
          <strong>Validating address, please wait a moment.</strong> <br/> <br/> <br/>
          <small>
            &nbsp;<i className="fa fa-4x fa-spin fa-spinner"/>
          </small>
        </div> : !isEditAddress ?
          <>
            <div className="row address-modal__content">
              {modalType === 'SUGGESTION' ?
                <div className="address-modal__info-suggestion d-flex justify-content-around">
                  <div className="d-inline address-modal__current-address">
                    <strong>Address on this account:</strong>
                    <br/>
                    <br/>
                    {currentAddressElement}
                  </div>
                  <div className="d-inline address-modal__suggested-address">
                    <strong>Suggested address:</strong>
                    <br/>
                    <br/>
                    <div className="square-bordered-info">
                      {suggestionAddress.addressModalLine1 ? <> {suggestionAddress.addressModalLine1} <br/> </> : null}
                      {suggestionAddress.addressModalLine2 ? <> {suggestionAddress.addressModalLine2} <br/> </> : null}
                      {suggestionAddress.addressModalCity}, {suggestionAddress.addressModalState} <br/>
                      {suggestionAddress.addressModalZip}
                    </div>
                  </div>
                </div>

                : modalType === 'INVALID' ? <>
                    <br/>
                    <br/>
                    <div className="text-center">
                      <strong className="d-block text-red">The following address could not be validated.</strong><br/>
                      <strong className="d-block">Please update your records.</strong>
                    </div>
                    <div className="address-modal__info-invalid d-flex justify-content-around ">
                      <div className="d-inline address-modal__suggested-address">
                        <br/>
                        {/*<br/>*/}
                        <strong>Current address:</strong>
                        {/*<br/>*/}
                        {/*<br/>*/}
                        {currentAddressElement}
                      </div>
                    </div>
                  </> :
                  <div className="address-modal__info-perfect d-flex justify-content-around ">
                    <div className="d-inline address-modal__current-address">
                      <br/>
                      <strong>Current address:</strong>
                      {currentAddressElement}
                    </div>
                  </div>
              }

              <a title="Access our support"
                 href="https://support.flowroute.com/299286-Setting-up-your-account---Addresses"
                 target="_blank" className="d-block text-right address-modal__help-text">Why is this important?</a>
              {/*<<<<<<<<<<warning alert block start>>>>>>>>>>>>*/}
              {isWarningModalVisible &&
              <div className={modalType == 'INVALID' ? "invalid-modal-container" : "suggested-modal-container"} data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog warning-dialog">
                  <div className="modal-content warning-content">
                    <div>
                      <h4 className="warning-text">Warning:</h4>
                      <p className="warning-desc">
                        This address cannot be validated; using it may result in higher state and local tax
                        rates. Please review your entry for accuracy.
                      </p>
                      <button type="button" className="button-v2 button-v2--change address-modal__button text-center warning-btn"
                              onClick={() => handlingWarningModal(false)}><b>I understand</b>
                      </button>
                      <p>For more assistance <a href="mailto:support@flowroute.com"
                                                className="warning-link"
                                                target="_blank">contact
                        support</a></p>
                    </div>
                  </div>
                </div>
              </div>
              }
              {/*<<<<<<<<<<warning alert block end>>>>>>>>>>>>*/}
            </div>
            <div className="modal-footer address-modal__footer">
              {modalType === 'SUGGESTION' ?
                <div className="d-flex justify-content-between">
                  <div className="col-xs-6">
                    <div>
                      <button type="button" className="button-v2 button-v2--change address-modal__button text-center btn-orange"
                              onClick={openEditMyAddress}>
                        <b>Edit my address</b></button>
                    </div>
                  </div>
                  <div className="col-xs-6">
                    <div>
                      <button type="button" className="button-v2 button-v2--success d-block" data-dismiss="modal"
                              onClick={setModifyCustomerPCode}><b>Replace with
                        suggested address</b></button>
                    </div>
                  </div>
                </div>
                : modalType === 'INVALID' &&
                <div className="text-center">
                  <div className="col-xs-offset-3 col-xs-6">
                    <button type="button" className="button-v2 button-v2--change address-modal__button text-center btn-orange"
                            onClick={openEditMyAddress}>
                      <b>Edit my address</b>
                    </button>
                  </div>
                </div>
              }
            </div>
          </> :
          // <<<<<<<<<Updating Address Block Start>>>>>>>>>>>
          <div>
            <div className="address-edit-container">
              <form onSubmit={handleAddressUpdate}>
                <div className="form-group">
                  <input type="text"
                         className={`form-control ${isSubmitted && addressValidation.street ? "error" : ""}`}
                         name="street" value={addressInputs.street || ""}
                         onChange={handleAddressChange}
                         placeholder="Address 1"/>
                </div>
                <div className="form-group">
                  <input type="text"
                         className="form-control"
                         name="street2" value={addressInputs.street2 || ""}
                         onChange={handleAddressChange}
                         placeholder="Address 2(optional)"/>
                </div>
                <div className="form-group">
                  <input type="text"
                         className={`form-control ${isSubmitted && addressValidation.city ? "error" : ""}`}
                         name="city" value={addressInputs.city || ""}
                         onChange={handleAddressChange}
                         placeholder="City"/>
                </div>
                <div className="form-group">
                  <input type="text"
                         className={`form-control ${isSubmitted && addressValidation.state ? "error" : ""}`}
                         name="state" value={addressInputs.state || ""}
                         onChange={handleAddressChange}
                         placeholder="State/Province"/>
                </div>
                <div className="form-group">
                  <input type="text"
                         className={`form-control ${isSubmitted && addressValidation.postal_code ? "error" : ""}`}
                         name="postal_code" value={addressInputs.postal_code || ""} onChange={handleAddressChange}
                         placeholder="ZIP/postal"/>
                </div>
                <div className="form-group">
                  <select
                    className={`form-control ${isSubmitted && addressValidation.iso_country ? "error" : ""}`}
                    name="iso_country" value={addressInputs.iso_country || ""}
                    onChange={handleAddressChange}>
                    {countryList.map((value, index) => {
                      return <option key={index} value={value.value}>{value.label}</option>
                    })}
                  </select>
                </div>

                <button type="submit" className="button-v2 button-v2--change text-center validate-btn">Validate Address
                </button>
              </form>
            </div>
          </div>
          // <<<<<<<<<Updating Address Block End>>>>>>>>>>>>
        }
      </Modal>
    </div>
  );
}

const render = () => {
  RenderRootComponent(hot(module)(AddressModal), "address-modal");
};

(async () => {
  try {
    RenderRootComponent(hot(module)(LoadingState), "address-modal");

    await exchangeCredentials(store);

    render();
  } catch (e) {
    console.warn(e);
  }
})();
