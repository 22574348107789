import { parseResponse, parseBody} from 'app/utils';
import { fetchWithCsrfToken } from 'app/api/fetch';
import { store } from "app/redux/store";
import { api } from 'app/api/index';


export function modifyCustomerPCode (data) {
    return fetchWithCsrfToken('/api/customer/pcode', {
      method: 'PUT',
      credentials: 'include',
      body: parseBody(data)
    }).then(parseResponse);
}

export function verifyCustomerAddress () {
    return fetchWithCsrfToken('/api/customer/verify_address', {
      method: 'PUT',
      credentials: 'include'
    }).then(parseResponse);
  }


export function postAddressValidation(data) {
    const { credentials } = store.getState();

    return api(
        '/v2/accounts/addresses/validate',
        { credentials, getMeta: true, body: data }
    );
  }

